
import { defineComponent, onMounted } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "widgets-mixed",
  components: {
  
  },
  setup() {
    onMounted(() => {
      //setCurrentPageBreadcrumbs("Mixed", ["Widgets"]);
    });
  },
});
